import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const availableLocales = {
  da: 'da_DK',
  de: 'de_DE',
  es: 'es_ES',
  fr: 'fr_FR',
  lt: 'lt_LT',
  nl: 'nl_NL',
  pt: 'pt_BR',
  sv: 'sv_SE',
  ca: 'es_ES',
  eu: 'es_ES',
  gl: 'es_ES',
}

const receivedLocale = window.marketgoo?.locale || 'en_US'

export const [currentLocale] = receivedLocale.split('_')

const translationFile = availableLocales[currentLocale] || 'en_US'

i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: currentLocale,
    backend: {
      loadPath: `/wp-content/plugins/${window.marketgoo.pluginDir}/translations/${translationFile}.json?v=${window.marketgoo.currentVersion}`,
    },
    fallbackLng: 'en',
  })

export default i18next
