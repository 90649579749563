import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
const { baseURL, nonce, partnerKey, accessToken, uuid, pluginDir } =
  window.marketgoo

// TODO: Pending to remove selectors and implement it on usePost directly
// TODO: Pending replace _marketgoo-development with variable
export const selectors = {
  description: `_${pluginDir}_${uuid}_description`,
  keyword: `_${pluginDir}_${uuid}_keyword`,
  keywordId: `_${pluginDir}_${uuid}_keywordId`,
  score: `_${pluginDir}_${uuid}_score`,
  title: `_${pluginDir}_${uuid}_title`,
}

export const headers = {
  [`${partnerKey}-authorization`]: `Bearer ${accessToken}`,
  'X-WP-Nonce': nonce || false,
}

export const baseRequest = axios.create({
  baseURL,
  headers,
})

export const request = applyCaseMiddleware(baseRequest, {
  ignoreHeaders: true,
  preservedKeys: ['keywordId', ...Object.values(selectors)],
})

const GET = 'GET'
const POST = 'POST'

const metaTagsGenerator = {
  create: ({ url, keyword }) => ({
    url: `${partnerKey}/v1/metatags/autocomplete`,
    method: POST,
    data: { url, keyword },
  }),
}

const checks = {
  create: (body) => ({
    url: `${partnerKey}/v1/checks`,
    method: POST,
    data: body,
  }),
  get: (id) => ({
    url: `${partnerKey}/v1/checks/${id}`,
    method: GET,
  }),
}

// keywords: caballo
const keywordSearch = {
  read: (keywords) => ({
    url: `${partnerKey}/v1/keyword-search`,
    method: POST,
    data: keywords,
  }),
}

const recommendedKeyword = {
  read: ({ hash, data }) => ({
    url: `${partnerKey}/v1/suggest-recommend-keyword/${hash}`,
    method: POST,
    data,
  }),
}

const meta = {
  create: ({ postId, data }) => ({
    url: `${partnerKey}/v1/page/create/${postId}/meta`,
    method: POST,
    data,
  }),
  read: ({ popId, key }) => ({
    url: `${partnerKey}/v1/page/${popId}/meta/${key}`,
    method: GET,
  }),
}

const ctas = {
  read: () => ({ url: `${partnerKey}/v1/ctas`, method: GET }),
}

const dataAccount = {
  get: () => ({ url: `${partnerKey}/v1/data`, method: GET }),
}

const tokenStatus = {
  read: () => ({ url: `${partnerKey}/v1/token-status`, method: POST }),
}

const popTypes = {
  page: 'pages',
  post: 'posts',
}

const searchAppearanceDescription = {
  get: ({ id, type }) => ({
    url: `${partnerKey}/v1/${popTypes[type]}/${id}/description`,
    method: GET,
  }),
}

const searchAppearanceTitle = {
  get: ({ id, type }) => ({
    url: `${partnerKey}/v1/${popTypes[type]}/${id}/title`,
    method: GET,
  }),
}

const wpNS = '/wp/v2'

const posts = {
  get: (id) => ({
    url: id ? `${wpNS}/posts/${id}` : `${wpNS}/posts`,
    method: GET,
  }),
  edit: ({ postId, data } = {}) => ({
    url: `${wpNS}/posts/${postId}`,
    method: POST,
    data,
  }),
}

const pop = {
  get: ({ id, type }) => ({
    url: `${wpNS}/${popTypes[type]}/${id}`,
    method: GET,
  }),
  edit: ({ id, type, data } = {}) => ({
    url: `${wpNS}/${popTypes[type]}/${id}`,
    method: POST,
    data,
  }),
}

const pages = {
  get: (id) => ({
    url: id ? `${wpNS}/pages/${id}` : `${wpNS}/pages`,
    method: GET,
  }),
}

export const endpoints = {
  searchAppearanceDescription,
  searchAppearanceTitle,
  metaTagsGenerator,
  checks,
  ctas,
  dataAccount,
  keywordSearch,
  meta,
  pages,
  pop,
  posts,
  recommendedKeyword,
  tokenStatus,
}
