import { useCtas } from '#hooks'
import {
  Button,
  Flex,
  FlexItem,
  Icon,
  Modal,
  Path,
  SVG,
} from '@wordpress/components'
import { useTranslation } from 'react-i18next'

import '#components/UpgradeModal/styles.css'

const CheckIcon = () => (
  <Icon
    icon={
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="#17ab4d"
        viewBox="0 0 256 256"
      >
        <Path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z" />
      </SVG>
    }
  />
)

export const UpgradeModal = ({ closeModal }) => {
  const { t } = useTranslation()
  const { data: availableCtas } = useCtas()

  const upgradeCta = availableCtas?.find(
    (cta) => cta.id === 'wordpress-upgrade',
  )

  return (
    <Modal
      title={t('freemium.moda.score.title')}
      className="mktseo-modal--black"
      onRequestClose={closeModal}
    >
      <p className="modal-subtitle">{t('freemium.modal.intro')}</p>
      <div className="modal-features">
        <Flex justify="space-around">
          <FlexItem>
            <ul>
              <li className="modal-features--item">
                <CheckIcon />
                <span>{t('freemium.modal.features.1')}</span>
              </li>
              <li className="modal-features--item">
                <CheckIcon />
                <span>{t('freemium.modal.features.2')}</span>
              </li>
              <li className="modal-features--item">
                <CheckIcon />
                <span>{t('freemium.modal.features.3')}</span>
              </li>
            </ul>
          </FlexItem>
          <FlexItem>
            <ul>
              <li className="modal-features--item">
                <CheckIcon />
                <span>{t('freemium.modal.features.4')}</span>
              </li>
              <li className="modal-features--item">
                <CheckIcon />
                <span>{t('freemium.modal.features.5')}</span>
              </li>
              <li className="modal-features--item">
                <CheckIcon />
                <span>{t('freemium.modal.features.6')}</span>
              </li>
            </ul>
          </FlexItem>
        </Flex>
      </div>
      <Flex justify="center">
        <FlexItem>
          <Button
            className="upgrade-btn"
            variant="primary"
            href={upgradeCta?.attributes.targetUrl}
          >
            {t('freemium.cta')}
          </Button>
        </FlexItem>
      </Flex>
    </Modal>
  )
}
