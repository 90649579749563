import { useState } from '@wordpress/element'

export const Tooltip = ({ text, children }) => {
  const [isHovered, setIsHovered] = useState(false)

  const styles = {
    container: {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
    },
    tooltip: {
      visibility: isHovered ? 'visible' : 'hidden',
      width: '200px',
      backgroundColor: 'black',
      color: '#fff',
      textAlign: 'left',
      borderRadius: '6px',
      padding: '10px',
      position: 'absolute',
      top: '100%',
      left: '50%',
      zIndex: '1',
    },
    text: {
      fontWeight: 'bold',
    },
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      style={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <div style={styles.tooltip}>
        <span style={styles.text}>{text}</span>
      </div>
    </div>
  )
}
